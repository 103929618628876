
import Form from '../Form'
import Vue from 'vue'
import {bus} from "@/main"
import { InputInterface } from '../interface'
export default Vue.extend({
    props:{
        form:Form
    },
    watch:{
        "$route.name" : {
            handler: function (val, oldVal) {
               console.log("change")
            },
            deep: true
        }
    },
    created(){
        bus.$on('changeFormStateKey' , (payload : any) => {
            let state = this.form.state as any
            state[payload.key] = payload.value

        })
    },
    methods:{
        // this method will be code when any input in the form changed
        change(input:InputInterface) {
            this.form.valid = false
            let form =  this.form.state
            form[input.name as keyof typeof form] = input.value
            if(this.form.validate()){
                this.form.valid = true
                this.$emit('change' , input.value)
            }
        },
        fileChange(val:InputInterface) {
            let form =  this.form.state
            form[val.name as keyof typeof form] = val.value
        }
    },
    
})
