import i18n from '@/i18n';
import { FormInterface, InputInterface } from './interface';
import Input from './inputs/Input';


export default class Form implements FormInterface {
    inputs: Input[]
    injectStateObj:Object
    loading: boolean = false
    error: string = ""
    public state: Object = {} as any
    valid: boolean = true
    hasSubmit: boolean = true
    hasValidation: boolean = false
    submit?: (form: any) => Promise<any>
    callBack?: (form: any) => any

    constructor(fromData: FormInterface) {

        this.inputs = fromData.inputs
        if (fromData.submit) this.submit = fromData.submit
        if (fromData.loading) this.loading = fromData.loading
        if (fromData.valid) this.valid = fromData.valid
        if (fromData.callBack) this.callBack = fromData.callBack
        this._initInputs()
    }

    public validate(): boolean {
        if (this.hasValidation == false) {
            return true
        }
        for (let index = 0; index < this.inputs.length; index++) {
            const input = this.inputs[index];
            if (input.field.required == true && (input.field.value == null || typeof input.field.value == 'undefined')) {
                return false
            }

        }
        return true
    }

    public submitAction() {
        this.loading = true
        if (!this.validate()) {
            this.error = i18n.t('required_validation_error').toString()
            this.loading = false
            return
        }
        this.state = {...this.state , ...this.injectStateObj}
        console.log("Asd")
        console.log(this.state)
        this.submit!(this.state).then((res : any) => {
            this._reset()
            this.valid = true
            if (this.callBack) {
                this.callBack(res)
            }
        }).catch(((e : any) => {
            this._reset()
            this.error = e
            this.error = i18n.t((e.response.data)).toString()
            if( typeof e.response !== 'undefined' || e.response.status === 400){
                this.valid = false
            }
    
        }))
    }
    public resetState() {        
        let state = {} as any
        this.inputs.forEach((input: Input) => {
            if (input.field.required) {
                this.valid = false
                this.hasValidation = true
            }
            state[input.field.name] = null
        })
        this.state = state

    }
    public  injectState(obj:object) {
        this.injectStateObj = obj
    }
    // this function responsible for creating object of all inputs with key value to use it in submit or whatever
    // and if the input is select or combobox and needs to get the items from the server we get this from here too
    private _initInputs() {
        this.state = []
        let state = {} as any
        this.inputs.forEach((input: Input) => {
            if (input.field.required) {
                this.valid = false
                this.hasValidation = true
            }
            state[input.field.name] = typeof input.field.initial == 'undefined' ? '' :  input.field.initial
        })
        this.state = state
    }

    private _reset() {
        // this._initInputs()
        this.loading = false
    }
}